import { put, take } from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'
import { auth } from '../../firestore'

import {
  browserLocalPersistence,
  setPersistence,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut
} from 'firebase/auth'

import {
  loginFailure,
  loginSuccess,
  addNotification,
  redirect,
  validateAuthSuccess,
  validateAuthFailure,
  logoutUserSuccess,
  logoutUserFailure
} from '../actions'

import { uiHelper } from '../../helpers'
import { uiConstants } from '../../constants'

export function* loginEmailPasswordSaga({
  payload
}: any): Generator<any, void, unknown> {
  try {
    const listener = eventChannel((emit) => {
      const unsubscribe = setPersistence(auth, browserLocalPersistence).then(
        () => {
          return signInWithEmailAndPassword(
            auth,
            payload.email,
            payload.password
          )
            .then((result) => {
              emit(result)
            })
            .catch((error) => {
              emit(error)
            })
        }
      )
      return () => unsubscribe
    })
    while (true) {
      yield take(listener)
      yield put(loginSuccess())
      yield put(redirect('/admin/events'))
    }
  } catch (error) {
    yield put(loginFailure(uiHelper.errorMessage(error)))
    yield put(
      addNotification(
        uiHelper.errorMessage(error),
        uiConstants.notification.error
      )
    )
  }
}

export function* validateAuthSaga(): Generator<any, void, unknown> {
  try {
    const listener = eventChannel((emit) => {
      const unsubscribe = onAuthStateChanged(auth, (result) => {
        if (result) {
          emit({
            uid: result.uid,
            displayName: result.displayName
          })
        } else {
          emit(false)
        }
      })
      return () => unsubscribe
    })
    while (true) {
      const user = yield take(listener)
      if (user) {
        yield put(validateAuthSuccess())
        if (window.location.pathname === '/admin') {
          yield put(redirect(`/admin/guestbook`))
        }
      } else {
        yield put(validateAuthFailure())
        yield put(redirect(`/admin`))
      }
    }
  } catch (error) {
    yield put(validateAuthFailure())
    yield put(redirect(`/`))
  }
}

export function* logoutUserSaga() {
  try {
    const listener = eventChannel((emit) => {
      const unsubscribe = signOut(auth)
        .then(() => {
          emit(true)
        })
        .catch((error) => {
          emit(error)
        })
      return () => unsubscribe
    })
    while (true) {
      yield take(listener)

      yield put(logoutUserSuccess())
      yield put(redirect(`/`))
    }
  } catch (error) {
    console.log(error)
    yield put(logoutUserFailure())
    yield put(
      addNotification(
        uiHelper.errorMessage(error),
        uiConstants.notification.error
      )
    )
  }
}
