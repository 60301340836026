import { put, take } from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'
import { galleryRef, database } from '../../database'
import { storage } from '../../firestore'
import {
  onSnapshot,
  query,
  orderBy,
  limit,
  addDoc,
  deleteDoc,
  doc
} from 'firebase/firestore'
import { deleteObject, ref, uploadBytes } from 'firebase/storage'

import {
  updateGallery,
  removeGallery,
  addNotification,
  galleryHideLoading
} from '../actions'
import { uiHelper } from '../../helpers'
import { uiConstants, firestoreConstants } from '../../constants'
import { firestoreHelper } from '../../helpers/firestore.helpers'

export let gallerySnap: any

export function* loadGallerySaga(): Generator<any, any, any> {
  try {
    const listener = eventChannel((emit) => {
      const q = query(galleryRef, orderBy('time', 'desc'), limit(30))
      gallerySnap = onSnapshot(q, (querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          emit(change)
        })
      })
      return () => gallerySnap()
    })
    while (true) {
      const change = yield take(listener)
      const data = change.doc.data()
      const id = change.doc.id
      if (id === '_._') continue
      if (change.type === 'added') {
        yield put(updateGallery({ id, ...data }))
      }
      if (change.type === 'modified') {
        yield put(updateGallery({ id, ...data }))
      }
      if (change.type === 'removed') {
        yield put(removeGallery({ id, ...data }))
      }
    }
  } catch (error) {
    console.log(error)
    yield put(
      addNotification(
        uiHelper.errorMessage(error),
        uiConstants.notification.error
      )
    )
  }
}

export function* addGallerySaga(action: any): Generator<any, any, any> {
  try {
    const buffer = action.payload.buffer
    delete action.payload.buffer

    const storageRef = ref(
      storage,
      firestoreConstants.gallery + '/' + action.payload.image
    )
    yield uploadBytes(
      storageRef,
      buffer,
      firestoreHelper.metadata(action.payload.image)
    )
    yield addDoc(galleryRef, action.payload)
    yield put(
      addNotification(
        uiConstants.messages.gallery_add_success,
        uiConstants.notification.success
      )
    )
  } catch (error) {
    yield put(
      addNotification(
        uiHelper.errorMessage(error),
        uiConstants.notification.error
      )
    )
  } finally {
    yield put(galleryHideLoading())
  }
}

export function* removeGallerySaga(action: any): Generator<any, any, any> {
  try {
    const storageRef = ref(
      storage,
      firestoreConstants.gallery + '/' + action.payload.image
    )

    yield deleteObject(storageRef)

    yield deleteDoc(
      doc(database, firestoreConstants.gallery, action.payload.id)
    )
    yield put(
      addNotification(
        uiConstants.messages.gallery_remove_success,
        uiConstants.notification.success
      )
    )
  } catch (error) {
    yield put(
      addNotification(
        uiHelper.errorMessage(error),
        uiConstants.notification.error
      )
    )
  } finally {
    yield put(galleryHideLoading())
  }
}
