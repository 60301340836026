import { Suspense, lazy } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Provider } from 'react-redux'

import { store } from './redux/store'

import 'react-loading-skeleton/dist/skeleton.css'

import PageLoader from './components/UI/PageLoader/PageLoader'

const LayoutLazy = lazy(() => import('./components/Containers/Layout/Layout'))
const AdminLayoutLazy = lazy(
  () => import('./components/Containers/AdminLayout/AdminLayout')
)

const HomeLazy = lazy(() => import('./components/Pages/Home/Home'))
const MaxLazy = lazy(() => import('./components/Pages/Max/Max'))
const WeddingLazy = lazy(() => import('./components/Pages/Wedding/Wedding'))
const GalleryLazy = lazy(() => import('./components/Pages/Gallery/Gallery'))
const ThoughtsLazy = lazy(() => import('./components/Pages/Thoughts/Thoughts'))
const DiaryLazy = lazy(() => import('./components/Pages/Diary/Diary'))
const GuestbookLazy = lazy(
  () => import('./components/Pages/Guestbook/Guestbook')
)
const AdminLazy = lazy(() => import('./components/Pages/Admin/Admin'))
const AdminEditorLazy = lazy(
  () => import('./components/Pages/AdminEditor/AdminEditor')
)
const CoursesEventsLazy = lazy(
  () => import('./components/Pages/CoursesEvents/CoursesEvents')
)

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Suspense fallback={<PageLoader />}>
          <Routes>
            <Route element={<LayoutLazy />}>
              <Route path='/' element={<HomeLazy />} />
              <Route path='/max' element={<MaxLazy />} />
              <Route path='/wedding' element={<WeddingLazy />} />
              <Route path='/gallery' element={<GalleryLazy />} />
              <Route path='/thoughts' element={<ThoughtsLazy />} />
              <Route path='/diary' element={<DiaryLazy />} />
              <Route path='/guestbook' element={<GuestbookLazy />} />
              <Route path='/courses-events' element={<CoursesEventsLazy />} />
              <Route path='/archive' element={<CoursesEventsLazy />} />

              <Route path='/admin' element={<AdminLazy />} />
              <Route element={<AdminLayoutLazy />}>
                <Route path='/admin/:section' element={<AdminEditorLazy />} />
              </Route>
              <Route path='*' element={<HomeLazy />} />
            </Route>
          </Routes>
        </Suspense>
      </BrowserRouter>
    </Provider>
  )
}

export default App
