import { authConstants } from '../constants'

export const loginEmailPassword = (payload: any) => {
  return {
    type: authConstants.AUTH_LOGIN_EMAIL_PASSWORD,
    payload
  }
}

export const loginSuccess = () => {
  return {
    type: authConstants.AUTH_LOGIN_SUCCESS
  }
}

export const loginFailure = (payload: string) => {
  return {
    type: authConstants.AUTH_LOGIN_FAILURE,
    payload
  }
}

export const validateAuth = () => {
  return {
    type: authConstants.AUTH_VALIDATE
  }
}

export const validateAuthSuccess = () => {
  return {
    type: authConstants.AUTH_VALIDATE_SUCCESS
  }
}

export const validateAuthFailure = () => {
  return {
    type: authConstants.AUTH_VALIDATE_FAILURE
  }
}

export const logoutUser = () => {
  return {
    type: authConstants.AUTH_LOGOUT
  }
}

export const logoutUserSuccess = () => {
  return {
    type: authConstants.AUTH_LOGOUT_SUCCESS
  }
}

export const logoutUserFailure = () => {
  return {
    type: authConstants.AUTH_LOGOUT_FAILURE
  }
}
