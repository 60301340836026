import { notifyConstants } from '../constants'
import { uiConstants } from '../../constants'
import { timeHelper, securityHelper } from '../../helpers'

export function addNotification(
  message: string,
  severity: string = uiConstants.notification.info
) {
  return (dispatch: any) => {
    const notification = {
      message,
      time: timeHelper.now(),
      guid: securityHelper.guid(),
      severity
    }
    dispatch(pushNotificationToQueue(notification))
    setTimeout(() => {
      dispatch(removeNotification(notification.guid))
    }, 5000)
  }
}

function pushNotificationToQueue(payload: any) {
  return {
    type: notifyConstants.PUSH_NOTIFICATION,
    payload
  }
}

export function removeNotification(guid: string) {
  return {
    type: notifyConstants.REMOVE_NOTIFICATION,
    payload: guid
  }
}
