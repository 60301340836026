import { guestbookConstants } from '../constants'

export const loadGuestbook = () => {
  return {
    type: guestbookConstants.GUESTBOOK_INIT
  }
}

export const updateGuestbook = (payload: any) => {
  return {
    type: guestbookConstants.GUESTBOOK_UPDATE,
    payload
  }
}

export const removeGuestbook = (payload: any) => {
  return {
    type: guestbookConstants.GUESTBOOK_REMOVE,
    payload
  }
}

export const addGuestbook = (payload: any) => {
  return {
    type: guestbookConstants.GUESTBOOK_ADD,
    payload
  }
}

export const deleteGuestbook = (payload: any) => {
  return {
    type: guestbookConstants.GUESTBOOK_DELETE,
    payload
  }
}

export const guestbookHideLoading = () => {
  return {
    type: guestbookConstants.GUESTBOOK_HIDE_LOADING
  }
}
