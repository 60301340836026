export const authConstants = {
  AUTH_LOGIN_EMAIL_PASSWORD: '[Auth] Login email password',
  AUTH_LOGIN_SUCCESS: '[Auth] Login success',
  AUTH_LOGIN_FAILURE: '[Auth] Login error',
  AUTH_LOGOUT: '[Auth] Logout',
  AUTH_LOGOUT_SUCCESS: '[Auth] Logout success',
  AUTH_LOGOUT_FAILURE: '[Auth] Logout failure',
  AUTH_VALIDATE: '[Auth] Validate',
  AUTH_VALIDATE_FAILURE: '[Auth] Validate failure',
  AUTH_VALIDATE_SUCCESS: '[Auth] Validate success'
}
