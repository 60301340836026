import { db } from './firestore'
import { collection } from 'firebase/firestore'
import { firestoreConstants } from './constants'

export const database = db

export const eventsRef = collection(db, firestoreConstants.events)
export const diaryRef = collection(db, firestoreConstants.diary)
export const guestbookRef = collection(db, firestoreConstants.guestbook)
export const galleryRef = collection(db, firestoreConstants.gallery)
export const thoughtsRef = collection(db, firestoreConstants.thoughts)
export const weddingRef = collection(db, firestoreConstants.wedding)
