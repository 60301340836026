import { diaryConstants } from '../constants'

export const loadDiary = () => {
  return {
    type: diaryConstants.DIARY_INIT
  }
}

export const updateDiary = (payload: any) => {
  return {
    type: diaryConstants.DIARY_UPDATE,
    payload
  }
}

export const removeDiary = (payload: any) => {
  return {
    type: diaryConstants.DIARY_REMOVE,
    payload
  }
}

export const addDiary = (payload: any) => {
  return {
    type: diaryConstants.DIARY_ADD,
    payload
  }
}

export const deleteDiary = (payload: any) => {
  return {
    type: diaryConstants.DIARY_DELETE,
    payload
  }
}

export const diaryHideLoading = () => {
  return {
    type: diaryConstants.DIARY_HIDE_LOADING
  }
}
