import { configureStore } from '@reduxjs/toolkit'
import rootReducer from './reducers'
import { thunk } from 'redux-thunk'

import createSagaMiddleware from 'redux-saga'
import {
  watchEvents,
  watchWedding,
  watchThoughts,
  watchGuestbook,
  watchDiary,
  watchGallery,
  watchAuth
} from './sagas'

const sagaMiddleware = createSagaMiddleware()

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(thunk, sagaMiddleware),
  devTools: process.env.REACT_APP_ENV !== 'production'
})

sagaMiddleware.run(watchEvents)
sagaMiddleware.run(watchWedding)
sagaMiddleware.run(watchThoughts)
sagaMiddleware.run(watchGuestbook)
sagaMiddleware.run(watchDiary)
sagaMiddleware.run(watchGallery)
sagaMiddleware.run(watchAuth)
