import { takeEvery } from 'redux-saga/effects'
import {
  eventsConstants,
  diaryConstants,
  guestbookConstants,
  thoughtsConstants,
  weddingConstants,
  galleryConstants,
  authConstants
} from '../constants'

import { addEventsSaga, loadEventsSaga, removeEventsSaga } from './events.sagas'
import {
  addWeddingSaga,
  loadWeddingSaga,
  removeWeddingSaga
} from './wedding.sagas'
import {
  addThoughtsSaga,
  loadThoughtsSaga,
  removeThoughtsSaga
} from './thoughts.sagas'
import {
  addGuestbookSaga,
  loadGuestbookSaga,
  removeGuestbookSaga
} from './guestbook.sagas'
import { addDiarySaga, loadDiarySaga, removeDiarySaga } from './diary.sagas'
import {
  addGallerySaga,
  loadGallerySaga,
  removeGallerySaga
} from './gallery.sagas'
import {
  loginEmailPasswordSaga,
  logoutUserSaga,
  validateAuthSaga
} from './auth.sagas'

export function* watchAuth() {
  yield takeEvery(
    authConstants.AUTH_LOGIN_EMAIL_PASSWORD,
    loginEmailPasswordSaga
  )
  yield takeEvery(authConstants.AUTH_VALIDATE, validateAuthSaga)
  yield takeEvery(authConstants.AUTH_LOGOUT, logoutUserSaga)
}

export function* watchEvents() {
  yield takeEvery(eventsConstants.EVENTS_INIT, loadEventsSaga)
  yield takeEvery(eventsConstants.EVENTS_ADD, addEventsSaga)
  yield takeEvery(eventsConstants.EVENTS_DELETE, removeEventsSaga)
}

export function* watchWedding() {
  yield takeEvery(weddingConstants.WEDDING_INIT, loadWeddingSaga)
  yield takeEvery(weddingConstants.WEDDING_ADD, addWeddingSaga)
  yield takeEvery(weddingConstants.WEDDING_DELETE, removeWeddingSaga)
}

export function* watchThoughts() {
  yield takeEvery(thoughtsConstants.THOUGHTS_INIT, loadThoughtsSaga)
  yield takeEvery(thoughtsConstants.THOUGHTS_ADD, addThoughtsSaga)
  yield takeEvery(thoughtsConstants.THOUGHTS_DELETE, removeThoughtsSaga)
}

export function* watchGuestbook() {
  yield takeEvery(guestbookConstants.GUESTBOOK_INIT, loadGuestbookSaga)
  yield takeEvery(guestbookConstants.GUESTBOOK_ADD, addGuestbookSaga)
  yield takeEvery(guestbookConstants.GUESTBOOK_DELETE, removeGuestbookSaga)
}

export function* watchDiary() {
  yield takeEvery(diaryConstants.DIARY_INIT, loadDiarySaga)
  yield takeEvery(diaryConstants.DIARY_ADD, addDiarySaga)
  yield takeEvery(diaryConstants.DIARY_DELETE, removeDiarySaga)
}

export function* watchGallery() {
  yield takeEvery(galleryConstants.GALLERY_INIT, loadGallerySaga)
  yield takeEvery(galleryConstants.GALLERY_ADD, addGallerySaga)
  yield takeEvery(galleryConstants.GALLERY_DELETE, removeGallerySaga)
}
