import { notifyConstants } from '../constants'

const initialState = {
  queue: []
}

export default function notify(state = initialState, action: any) {
  switch (action.type) {
    case notifyConstants.PUSH_NOTIFICATION:
      return {
        ...state,
        queue: state.queue
          .filter((x: any) => x.message !== action.payload.message)
          .concat(action.payload)
      }
    case notifyConstants.REMOVE_NOTIFICATION:
      return {
        ...state,
        queue: state.queue.filter((n: any) => n.guid !== action.payload)
      }
    default:
      return state
  }
}
