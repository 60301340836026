import { guestbookConstants } from '../constants'

const initialState = {
  list: null,
  init: false
}

export default function guestbook(state = initialState, action: any) {
  switch (action.type) {
    case guestbookConstants.GUESTBOOK_INIT:
      return {
        ...state,
        init: true
      }
    case guestbookConstants.GUESTBOOK_UPDATE:
      return {
        ...state,
        list: (state.list === null
          ? [action.payload]
          : (state.list as any[]).findIndex(
              (x: any) => x.id === action.payload.id
            ) === -1
          ? (state.list as any[]).concat(action.payload)
          : (state.list as any[]).map((item: any) => {
              return item.id === action.payload.id ? action.payload : item
            })
        ).sort((a: any, b: any) => b.time - a.time)
      }
    case guestbookConstants.GUESTBOOK_REMOVE:
      return {
        ...state,
        list: (state.list || [])
          .filter((item: any) => {
            return item.id !== action.payload.id
          })
          .sort((a: any, b: any) => b.time - a.time)
      }
    case guestbookConstants.GUESTBOOK_ADD:
    case guestbookConstants.GUESTBOOK_DELETE:
      return {
        ...state,
        loading: true
      }
    case guestbookConstants.GUESTBOOK_HIDE_LOADING:
      return {
        ...state,
        loading: false
      }
    default:
      return state
  }
}
