import { combineReducers } from 'redux'

import auth from './auth.reducer'
import redirect from './redirect.reducer'
import notify from './notify.reducer'
import events from './events.reducer'
import diary from './diary.reducer'
import guestbook from './guestbook.reducer'
import thoughts from './thoughts.reducer'
import wedding from './wedding.reducer'
import gallery from './gallery.reducer'

const rootReducer = combineReducers({
  auth,
  redirect,
  notify,
  events,
  diary,
  guestbook,
  thoughts,
  wedding,
  gallery
})

export default rootReducer
