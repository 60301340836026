import Logo from '../../../assets/logo_white.svg'
import css from './PageLoader.module.scss'

const PageLoader = () => (
  <div className={css.LoaderBkg}>
    <div className={css.Loader}></div>
    <div className={css.Logo}>
      <img src={Logo} alt='Max Spinolo' />
    </div>
  </div>
)

export default PageLoader
