const errorMessage = (error: any) => {
  try {
    const e = error.response?.data?.message ?? error.message ?? error
    if (!e) {
      throw new Error('Generic Error')
    }
    return e
  } catch {
    return 'Generic Error'
  }
}

const formInitialValues = (fields: any, initialValues: any, setValue: any) => {
  fields.forEach((f: any) => {
    if (f.ignoreOnEdit || initialValues[f.name] === '') return
    setValue(f.name, initialValues[f.name] || '')
  })
}

const provider = (p: string) => {
  switch (p) {
    case 'github':
    default:
      return 'fa-brands fa-github'
  }
}

const colorPercent = (percent: number) => {
  if (percent > 80) return 'Green'
  if (percent > 60) return 'Yellow'
  if (percent > 40) return 'Orange'
  return 'Red'
}

export const uiHelper = {
  errorMessage,
  formInitialValues,
  provider,
  colorPercent
}
