export const uiConstants = {
  messages: {
    network_error: 'Network error',
    diary_add_success: 'Diario aggiunto con successo',
    diary_remove_success: 'Diario rimosso con successo',
    thoughts_add_success: 'Pensiero aggiunto con successo',
    thoughts_remove_success: 'Pensiero rimosso con successo',
    guestbook_add_success: 'Messaggio guestbook aggiunto con successo',
    guestbook_remove_success: 'Messaggio guestbook rimosso con successo',
    wedding_add_success: 'Wedding aggiunto con successo',
    wedding_remove_success: 'Wedding rimosso con successo',
    gallery_remove_success: 'Immagine rimossa con successo',
    gallery_add_success: 'Immagine aggiunta con successo',
    events_add_success: 'Evento aggiunto con successo',
    events_remove_success: 'Evento rimosso con successo'
  },
  notification: {
    info: 'Info',
    error: 'Error',
    warning: 'Warning',
    success: 'Success'
  },
  menu: [
    {
      label: 'Home',
      link: '/'
    },
    {
      label: 'Max',
      link: '/max'
    },
    {
      label: 'Corsi/Eventi',
      link: '/courses-events'
    },
    {
      label: 'Archivio',
      link: '/archive'
    },
    {
      label: 'Gallery',
      link: '/gallery'
    },
    {
      label: 'Wedding',
      link: '/wedding'
    },
    {
      label: 'Diario',
      link: '/diary'
    },
    {
      label: 'Pensieri',
      link: '/thoughts'
    },
    {
      label: 'Guestbook',
      link: '/guestbook'
    }
  ],
  adminMenu: [
    {
      label: 'Corsi / Eventi',
      link: '/admin/events'
    },
    {
      label: 'Gallery',
      link: '/admin/gallery'
    },
    {
      label: 'Wedding',
      link: '/admin/wedding'
    },
    {
      label: 'Diario',
      link: '/admin/diary'
    },
    {
      label: 'Pensieri',
      link: '/admin/thoughts'
    },
    {
      label: 'Guestbook',
      link: '/admin/guestbook'
    }
  ],
  social: [
    {
      icon: 'fa-solid fa-phone',
      link: 'tel:+39039660041'
    },
    {
      icon: 'fa-regular fa-envelope',
      link: 'mailto:info@maxspinolo.it'
    },
    {
      icon: 'fa-brands fa-facebook',
      link: 'https://www.facebook.com/massimo.spinolo'
    },
    {
      icon: 'fa-brands fa-youtube',
      link: 'https://www.youtube.com/channel/UClWRME14mn0agzchtzhWTRg'
    },
    {
      icon: 'fa-brands fa-instagram',
      link: 'https://www.instagram.com/tutanmax/'
    }
  ],
  recaptcha: {
    site_key: '6LfomhoqAAAAAAMIKzIuOww0vdfE6EsLOIo5iUx6'
  }
}
