import { put, take } from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'
import { thoughtsRef, database } from '../../database'
import { onSnapshot, addDoc, deleteDoc, doc } from 'firebase/firestore'

import {
  updateThoughts,
  removeThoughts,
  addNotification,
  thoughtsHideLoading
} from '../actions'
import { uiHelper } from '../../helpers'
import { uiConstants, firestoreConstants } from '../../constants'

export let thoughtsSnap: any

export function* loadThoughtsSaga(): Generator<any, any, any> {
  try {
    const listener = eventChannel((emit) => {
      thoughtsSnap = onSnapshot(thoughtsRef, (querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          emit(change)
        })
      })
      return () => thoughtsSnap()
    })
    while (true) {
      const change = yield take(listener)
      const data = change.doc.data()
      const id = change.doc.id
      if (id === '_._') continue
      if (change.type === 'added') {
        yield put(updateThoughts({ id, ...data }))
      }
      if (change.type === 'modified') {
        yield put(updateThoughts({ id, ...data }))
      }
      if (change.type === 'removed') {
        yield put(removeThoughts({ id, ...data }))
      }
    }
  } catch (error) {
    console.log(error)
    yield put(
      addNotification(
        uiHelper.errorMessage(error),
        uiConstants.notification.error
      )
    )
  }
}

export function* addThoughtsSaga(action: any): Generator<any, any, any> {
  try {
    yield addDoc(thoughtsRef, action.payload)
    yield put(
      addNotification(
        uiConstants.messages.thoughts_add_success,
        uiConstants.notification.success
      )
    )
  } catch (error) {
    yield put(
      addNotification(
        uiHelper.errorMessage(error),
        uiConstants.notification.error
      )
    )
  } finally {
    yield put(thoughtsHideLoading())
  }
}

export function* removeThoughtsSaga(action: any): Generator<any, any, any> {
  try {
    yield deleteDoc(doc(database, firestoreConstants.thoughts, action.payload))
    yield put(
      addNotification(
        uiConstants.messages.thoughts_remove_success,
        uiConstants.notification.success
      )
    )
  } catch (error) {
    yield put(
      addNotification(
        uiHelper.errorMessage(error),
        uiConstants.notification.error
      )
    )
  } finally {
    yield put(thoughtsHideLoading())
  }
}
