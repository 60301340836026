import { thoughtsConstants } from '../constants'

export const loadThoughts = () => {
  return {
    type: thoughtsConstants.THOUGHTS_INIT
  }
}

export const updateThoughts = (payload: any) => {
  return {
    type: thoughtsConstants.THOUGHTS_UPDATE,
    payload
  }
}

export const removeThoughts = (payload: any) => {
  return {
    type: thoughtsConstants.THOUGHTS_REMOVE,
    payload
  }
}

export const addThoughts = (payload: any) => {
  return {
    type: thoughtsConstants.THOUGHTS_ADD,
    payload
  }
}

export const deleteThoughts = (payload: any) => {
  return {
    type: thoughtsConstants.THOUGHTS_DELETE,
    payload
  }
}

export const thoughtsHideLoading = () => {
  return {
    type: thoughtsConstants.THOUGHTS_HIDE_LOADING
  }
}
