import { galleryConstants } from '../constants'

export const loadGallery = () => {
  return {
    type: galleryConstants.GALLERY_INIT
  }
}

export const updateGallery = (payload: any) => {
  return {
    type: galleryConstants.GALLERY_UPDATE,
    payload
  }
}

export const removeGallery = (payload: any) => {
  return {
    type: galleryConstants.GALLERY_REMOVE,
    payload
  }
}

export const addGallery = (payload: any) => {
  return {
    type: galleryConstants.GALLERY_ADD,
    payload
  }
}

export const deleteGallery = (payload: any) => {
  return {
    type: galleryConstants.GALLERY_DELETE,
    payload
  }
}

export const galleryHideLoading = () => {
  return {
    type: galleryConstants.GALLERY_HIDE_LOADING
  }
}
