import { put, take } from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'
import { guestbookRef, database } from '../../database'
import { onSnapshot, addDoc, deleteDoc, doc } from 'firebase/firestore'

import {
  updateGuestbook,
  removeGuestbook,
  addNotification,
  guestbookHideLoading
} from '../actions'
import { uiHelper } from '../../helpers'
import { uiConstants, firestoreConstants } from '../../constants'

export let guestbookSnap: any

export function* loadGuestbookSaga(): Generator<any, any, any> {
  try {
    const listener = eventChannel((emit) => {
      guestbookSnap = onSnapshot(guestbookRef, (querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          emit(change)
        })
      })
      return () => guestbookSnap()
    })
    while (true) {
      const change = yield take(listener)
      const data = change.doc.data()
      const id = change.doc.id
      if (id === '_._') continue
      if (change.type === 'added') {
        yield put(updateGuestbook({ id, ...data }))
      }
      if (change.type === 'modified') {
        yield put(updateGuestbook({ id, ...data }))
      }
      if (change.type === 'removed') {
        yield put(removeGuestbook({ id, ...data }))
      }
    }
  } catch (error) {
    console.log(error)
    yield put(
      addNotification(
        uiHelper.errorMessage(error),
        uiConstants.notification.error
      )
    )
  }
}

export function* addGuestbookSaga(action: any): Generator<any, any, any> {
  try {
    yield addDoc(guestbookRef, action.payload)
    yield put(
      addNotification(
        uiConstants.messages.guestbook_add_success,
        uiConstants.notification.success
      )
    )
  } catch (error) {
    yield put(
      addNotification(
        uiHelper.errorMessage(error),
        uiConstants.notification.error
      )
    )
  } finally {
    yield put(guestbookHideLoading())
  }
}

export function* removeGuestbookSaga(action: any): Generator<any, any, any> {
  try {
    yield deleteDoc(doc(database, firestoreConstants.guestbook, action.payload))
    yield put(
      addNotification(
        uiConstants.messages.guestbook_remove_success,
        uiConstants.notification.success
      )
    )
  } catch (error) {
    yield put(
      addNotification(
        uiHelper.errorMessage(error),
        uiConstants.notification.error
      )
    )
  } finally {
    yield put(guestbookHideLoading())
  }
}
