import { put, take } from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'
import { diaryRef, database } from '../../database'
import {
  onSnapshot,
  query,
  orderBy,
  limit,
  addDoc,
  deleteDoc,
  doc
} from 'firebase/firestore'

import {
  updateDiary,
  removeDiary,
  addNotification,
  diaryHideLoading
} from '../actions'
import { uiHelper } from '../../helpers'
import { uiConstants, firestoreConstants } from '../../constants'

export let diarySnap: any

export function* loadDiarySaga(): Generator<any, any, any> {
  try {
    const listener = eventChannel((emit) => {
      const q = query(diaryRef, orderBy('time', 'desc'), limit(30))
      diarySnap = onSnapshot(q, (querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          emit(change)
        })
      })
      return () => diarySnap()
    })
    while (true) {
      const change = yield take(listener)
      const data = change.doc.data()
      const id = change.doc.id
      if (id === '_._') continue
      if (change.type === 'added') {
        yield put(updateDiary({ id, ...data }))
      }
      if (change.type === 'modified') {
        yield put(updateDiary({ id, ...data }))
      }
      if (change.type === 'removed') {
        yield put(removeDiary({ id, ...data }))
      }
    }
  } catch (error) {
    console.log(error)
    yield put(
      addNotification(
        uiHelper.errorMessage(error),
        uiConstants.notification.error
      )
    )
  }
}

export function* addDiarySaga(action: any): Generator<any, any, any> {
  try {
    yield addDoc(diaryRef, action.payload)
    yield put(
      addNotification(
        uiConstants.messages.diary_add_success,
        uiConstants.notification.success
      )
    )
  } catch (error) {
    yield put(
      addNotification(
        uiHelper.errorMessage(error),
        uiConstants.notification.error
      )
    )
  } finally {
    yield put(diaryHideLoading())
  }
}

export function* removeDiarySaga(action: any): Generator<any, any, any> {
  try {
    yield deleteDoc(doc(database, firestoreConstants.diary, action.payload))
    yield put(
      addNotification(
        uiConstants.messages.diary_remove_success,
        uiConstants.notification.success
      )
    )
  } catch (error) {
    yield put(
      addNotification(
        uiHelper.errorMessage(error),
        uiConstants.notification.error
      )
    )
  } finally {
    yield put(diaryHideLoading())
  }
}
