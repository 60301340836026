import { DateTime, Settings } from 'luxon'

Settings.defaultZone = 'UTC'

const now = () => {
  return DateTime.utc().toUnixInteger()
}

const formatEpoch = (epoch: number, format = 'yyyy-MM-dd') => {
  return epoch === 0
    ? '-'
    : DateTime.fromSeconds(epoch).toUTC().toFormat(format)
}

const epochToFormat = (date: string, format = 'yyyy-MM-dd') => {
  return DateTime.fromFormat(date, format).toUTC().toUnixInteger()
}

const startDayToday = () => {
  return DateTime.utc().startOf('day').toUnixInteger()
}

export const timeHelper = {
  now,
  formatEpoch,
  startDayToday,
  epochToFormat
}
