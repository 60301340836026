import { eventsConstants } from '../constants'

export const loadEvents = () => {
  return {
    type: eventsConstants.EVENTS_INIT
  }
}

export const updateEvents = (payload: any) => {
  return {
    type: eventsConstants.EVENTS_UPDATE,
    payload
  }
}

export const removeEvents = (payload: any) => {
  return {
    type: eventsConstants.EVENTS_REMOVE,
    payload
  }
}

export const addEvents = (payload: any) => {
  return {
    type: eventsConstants.EVENTS_ADD,
    payload
  }
}

export const deleteEvents = (payload: any) => {
  return {
    type: eventsConstants.EVENTS_DELETE,
    payload
  }
}

export const eventsHideLoading = () => {
  return {
    type: eventsConstants.EVENTS_HIDE_LOADING
  }
}
