import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getAuth } from 'firebase/auth'
import { getStorage } from 'firebase/storage'
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyC3zAARsOV_88U1hM57ilChnsaHdNIUUZA',
  authDomain: 'maxspinolo.firebaseapp.com',
  projectId: 'maxspinolo',
  storageBucket: 'maxspinolo.appspot.com',
  messagingSenderId: '104395279150',
  appId: '1:104395279150:web:ebc4357b4f243fb5f008bf',
  measurementId: 'G-C3B05FS4X1'
}

export const app = initializeApp(firebaseConfig)
export const analytics = getAnalytics(app)
export const auth = getAuth(app)
export const db = getFirestore(app)
export const storage = getStorage(app)
