const metadata = (file: string) => {
  const ext = file.split('.').pop()?.toLowerCase()
  switch (ext) {
    case 'png':
      return {
        contentType: 'image/png'
      }
    default:
      return {
        contentType: 'image/jpeg'
      }
  }
}

export const firestoreHelper = {
  metadata
}
