import { weddingConstants } from '../constants'

export const loadWedding = () => {
  return {
    type: weddingConstants.WEDDING_INIT
  }
}

export const updateWedding = (payload: any) => {
  return {
    type: weddingConstants.WEDDING_UPDATE,
    payload
  }
}

export const removeWedding = (payload: any) => {
  return {
    type: weddingConstants.WEDDING_REMOVE,
    payload
  }
}

export const addWedding = (payload: any) => {
  return {
    type: weddingConstants.WEDDING_ADD,
    payload
  }
}

export const deleteWedding = (payload: any) => {
  return {
    type: weddingConstants.WEDDING_DELETE,
    payload
  }
}

export const weddingHideLoading = () => {
  return {
    type: weddingConstants.WEDDING_HIDE_LOADING
  }
}
